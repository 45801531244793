import React, { useState, ChangeEvent } from "react";
import Layout from "../components/layout";
import PageTitle from "../components/PageTitle";
import { TransitionContainer } from "../components/TransitionContainer";
import { Typography, Link, Grid } from "@material-ui/core";
import { graphql } from "gatsby";

type IndexProps = {
  data: any;
};

export default ({ data }: IndexProps) => {
  return (
    <Layout>
      <PageTitle fluidImage={data.file.childImageSharp.fluid} height="100%">
        <TransitionContainer direction="start">
          <Typography
            variant="h3"
            component="h1"
            paragraph
            style={{ marginTop: 200 }}
          >
            Sie finden mich mitten im Herzen der Frankfurter Bankenmetropole
          </Typography>
          <Typography variant="h5" paragraph>
            Haben Sie Fragen, Wünsche oder Anregungen? 
            Bitte wenden Sie sich zur Zwecks einer Vereinbarung eines Termins
            oder einer fernmündlichen Besprechung einfach und kostenfrei an mich
            via E-Mail. 
          </Typography>

          <Typography variant="h5" paragraph>
            <Link href="mailto:info@tajdari.de">info@tajdari.de</Link>
          </Typography>
          <Grid
            container
            spacing={10}
            style={{
              width: "100%",
              marginTop: 50,
              backgroundColor: "rgba(0,0,0,.4)",
            }}
          >
            <Grid item xs={12} md={6}>
              <Typography variant="h5" paragraph>
                Büro Frankfurt
              </Typography>
              <Typography paragraph>Steuerberater</Typography>
              <Typography paragraph>Mehdi Tajdari</Typography>
              <Typography paragraph>Am Hopfengarten 7</Typography>
              <Typography paragraph>60489 Frankfurt am Main</Typography>
            </Grid>
          </Grid>
        </TransitionContainer>
      </PageTitle>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "Z-frankfurt.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`;
